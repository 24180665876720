.found-title{
    font-size: large;
    font-weight: bold;
    margin-bottom: 0;
}
.feedback {
    text-align: center;
    margin: auto;
    max-width: 700px;
    color: red;
    font-size: large;
}
.button{
    color: #003F72; 
    background-color: #FFCC01; 
    border: none; 
    margin: auto; 
    padding: 9px 23px; 
    font-weight: bold; 
    border-radius: 16px; 
    font-size: 20px;
}
.button:disabled { 
    background-color: #EFEFED; 
}