.found-title {
  margin-bottom: 0;
  font-size: large;
  font-weight: bold;
}

.feedback {
  text-align: center;
  color: red;
  max-width: 700px;
  margin: auto;
  font-size: large;
}

.button {
  color: #003f72;
  background-color: #ffcc01;
  border: none;
  border-radius: 16px;
  margin: auto;
  padding: 9px 23px;
  font-size: 20px;
  font-weight: bold;
}

.button:disabled {
  background-color: #efefed;
}
/*# sourceMappingURL=index.6a833650.css.map */
